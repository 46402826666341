
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue";
import DetailModal from "../announcement-detail/Main.vue";
import MemberApproveModal from "../member/modal/Approve.vue";
import formatDate from "xe-utils/toDateString";
export default defineComponent({
  components: {
    Grid,
    CheckBoxList,
    DetailModal,
    MemberApproveModal
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const currentRow = ref<any>({});
    const modalVisible = ref(false);
    const approveModalVisible = ref(false);
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: "news",
      title: "最新快訊",
      multiselect: false,
      canCreate: false,
      canDelete: false,
      canUpdate: false,
      canRead: false,
      toolbarConfig: {
        custom: false,
        refresh: true
      },
      printConfig: {
        sheetName: "最新快訊",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "最新快訊",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "date",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd HH:mm"),
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "type",
          title: "分類",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "title",
          title: "標題",
          type: "html",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "publisher",
          title: "發布單位",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        }
      ],
      promises: {
        query: model
          ? params =>
              model.dispatch("news/query", {
                pageSize: params.pageSize,
                page: params.page
              })
          : undefined,
        queryAll: model ? () => model.dispatch("role/query") : undefined,
        save: model ? params => model.dispatch("role/save", params) : undefined
      },
      modalConfig: { showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: "right",
      items: [
        {
          field: "date",
          title: "時間",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "type",
          title: "分類",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "title",
          title: "標題",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "html" }
          }
        },
        {
          field: "publisher",
          title: "發布單位",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    const permissionItems = computed(() => {
      const items: any[] = [];
      for (const permission of Object.values(
        model?.enums.SystemPermission || {}
      )) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName);
          if (!group)
            items.push(
              (group = { name: permission.GroupName, items: [], order: 0 })
            );
          group.items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
          if (group.order < permission.Order) group.order = permission.Order;
        } else
          items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
      }
      return items.sort((a: any, b: any) => a.order - b.order);
    });

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems,
      currentRow,
      modalVisible,
      approveModalVisible
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    onCellClick(e: any) {
      console.log("🚀 ~ file: Main.vue ~ line 387 ~ onCellClick ~ e", e);

      this.currentRow = e.row;

      if (this.currentRow && this.currentRow.system === "News") {
        this.modalVisible = true;
      } else if (this.currentRow && this.currentRow.system === "Audit") {
        this.approveModalVisible = true;
      }

      // if (this.currentRow.id === 1) {
      //   this.approveModalVisible = true;
      // } else {
      //   if (this.currentRow) this.modalVisible = true;
      //   console.log(
      //     "🚀 ~ file: Main.vue ~ line 400 ~ onCellClick ~ modalVisible",
      //     this.modalVisible
      //   );
      // }
    }
  }
});
