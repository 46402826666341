
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import { toRef, watch } from "vue-demi";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";

export default defineComponent({
  components: {
    // Grid,
    // CheckBoxList,
    CfInputBox,
    CfInputRadioBox
  },
  props: {
    modalVisible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number
    }
  },
  setup(props, { emit }) {
    // const visible = toRef(props, "modalVisible");
    const data = ref({} as any);
    const model = CloudFun.current?.model;
    const reason = ref("false");
    const title = ref("系統通知");

    const loadAudit = (id: number) => {
      model?.dispatch("vendor/findAudit", id).then(
        payload => {
          data.value = payload.payload;
        },
        reason => {
          console.log("reason", reason);
        }
      );
    };

    const visible = computed<boolean>({
      get() {
        return props.modalVisible;
      },
      set(visible) {
        emit("update:modalVisible", visible);
      }
    });

    watch(
      () => props.id,
      current => {
        console.log(
          "🚀 ~ file: loadAudit ßMain.vue ~ line 90 ~ setup ~ current",
          current
        );

        if (current) loadAudit(current);
      }
    );

    return {
      visible,
      data,
      title,
      reason
    };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    }
  }
});
