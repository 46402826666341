<template>
  <vxe-modal
    title-align="right"
    v-model="visible"
    :title="title"
    resize
    remember
    transfer
    :showHeader="true"
    show-footer
    width="80%"
    height="80%"
    @close="close"
    @hide="close"
  >
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box h-full p-5">
      <div class=" flex justify-between items-center mb-5">
        <div class="">
          <a
            class="message-class py-1 bg-theme-3 text-lg text-white text-center mr-3 px-5 rounded-3xl w-1/5"
            href=""
            >系統</a
          >
          <span class=" w-full text-lg text-gray-600">{{ data?.date }}</span>
        </div>
        <div class="shareWrap flex justify-end items-center">
          <button class=" shareButton mr-2">
            <PrinterIcon class="w-6 h-6" />
          </button>
        </div>
      </div>

      <div class="text-xl font-bold mb-10">
        <!-- 組織成員申請加入  -->
        {{ data?.messageFromNews }}
        <!-- {{ data?.title }} -->
      </div>

      <form class=" max-w-screen-md">
        <!-- 表單內容 -->
        <!-- <div class="text-base text-gray-600 mb-5"> -->
        <!-- abc.cisa@cosa.net向鴻海科技股份有限公司提出加入申請，請進行審核： -->
        <!-- {{ data.messageFromNews }} -->
        <!-- </div> -->
        <div class=" sm:flex mb-5 text-base">
          <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
            真實姓名
          </div>
          <div class=" w-full ">
            {{ data?.name }}
          </div>
        </div>
        <div class=" sm:flex mb-5 text-base">
          <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
            業務信箱
          </div>
          <div class=" w-full break-words">
            {{ data?.email }}
          </div>
        </div>
        <!-- <div class="sm:flex mb-5 text-base">
          <CfInputBox
            label="職稱"
            name="jobName"
            placeholder="輸入職稱"
            type="text"
          ></CfInputBox>
        </div>
        <div class="sm:flex mb-5 text-base">
          <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
            權限
          </div>
          <div class="w-full">
            <select
              name=""
              id=""
              class="intro-x input input--lg border border-gray-300 w-full mt-2 sm:mt-0"
            >
              <option value="sale">業務</option>
              <option value="company">供應商</option>
            </select>
          </div>
        </div>
        <div class="sm:flex mb-5 text-base">
          <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
            審核
          </div>
          <div class="w-full">
            <div class="text-base mb-3">
              <CfInputRadioBox
                id="default"
                name="pass"
                defaultValue="false"
                :modelValue="reason"
                @update:modelValue="
                  val => {
                    reason = val;
                  }
                "
              ></CfInputRadioBox>
              <label class="cursor-pointer select-none" for="default">
                審核通過
              </label>
            </div>
            <div class="text-base mb-3">
              <CfInputRadioBox
                id="fail"
                name="reason"
                defaultValue="true"
                :modelValue="reason"
                @update:modelValue="
                  val => {
                    reason = val;
                  }
                "
              ></CfInputRadioBox>

              <label class="cursor-pointer select-none" for="fail">
                未通過
              </label>
              <div id="failInput" class=" pt-3" v-if="reason === 'true'">
                <CfInputBox
                  name="fail_reason"
                  placeholder="輸入未通過原因"
                  type="text"
                ></CfInputBox>
              </div>
            </div>
          </div>
        </div> -->
        <div class=" sm:flex mb-5 text-base">
          <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5"></div>
          <!-- <div class=" w-full break-words">
            <button
              type="submit"
              class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
            >
              <CheckIcon class="mr-2 w-4 h-4" />
              送出結果
            </button>
          </div> -->
        </div>
      </form>
    </div>
    <!-- END: HTML Table Data -->
  </vxe-modal>
</template>

<script lang="ts">
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import { toRef, watch } from "vue-demi";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";

export default defineComponent({
  components: {
    // Grid,
    // CheckBoxList,
    CfInputBox,
    CfInputRadioBox
  },
  props: {
    modalVisible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number
    }
  },
  setup(props, { emit }) {
    // const visible = toRef(props, "modalVisible");
    const data = ref({} as any);
    const model = CloudFun.current?.model;
    const reason = ref("false");
    const title = ref("系統通知");

    const loadAudit = (id: number) => {
      model?.dispatch("vendor/findAudit", id).then(
        payload => {
          data.value = payload.payload;
        },
        reason => {
          console.log("reason", reason);
        }
      );
    };

    const visible = computed<boolean>({
      get() {
        return props.modalVisible;
      },
      set(visible) {
        emit("update:modalVisible", visible);
      }
    });

    watch(
      () => props.id,
      current => {
        console.log(
          "🚀 ~ file: loadAudit ßMain.vue ~ line 90 ~ setup ~ current",
          current
        );

        if (current) loadAudit(current);
      }
    );

    return {
      visible,
      data,
      title,
      reason
    };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    }
  }
});
</script>
